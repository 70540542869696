<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="searchFilter">
          <SearchForm @searchTickets="searchTickets" userRole="first-level" />
        </div>

        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-stickies v-if="!$routerHistory.hasPrevious()"></b-icon-stickies> {{ label }} {{ totalTickets }}</div>
          <div class="card-body" style="background-color:#fff">
            <div v-if="spinner" class="text-center">
              <b-spinner variant="warning" label="Spinning"></b-spinner>
            </div>
            <!-- <List v-if="!spinner" :tickets="tickets" routeName="TicketDetailsFirstLevel"></List> -->
            <!-- Acition buttons -->
            <div class="row mb-3" v-if="selected.length > 1">
              <div class="col-12">
                <button class="btn btn-outline-dark btn-xs mr-3" @click="batchResolve">Resolve</button>
                <button class="btn btn-outline-dark btn-xs mr-3" @click="showChangeCategoryModal">Change category</button>
                <!-- <button class="btn btn-outline-dark btn-xs mr-3">Re-Assign</button> -->
              </div>
            </div>
            <!-- End acition button -->

            <table class="table table-hover table-sm table-bordered table-responsive-xl" style="font-size:.8rem">
              <thead class="thead-light">
                <tr>
                  <th class="text-center">
                    <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" aria-describedby="refPool" aria-controls="refPool" @change="toggleAll"><!-- {{ allSelected ? 'Un-select All' : 'Select All' }} --></b-form-checkbox>
                  </th>
                  <th v-for="c in columns" :key="c.field">
                    <span>{{ c.label }}</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="t in tickets" :key="t.ref">
                  <td class="text-center">
                    <b-form-checkbox-group id="flavors" v-model="selected" name="flavors" stacked>
                      <b-form-checkbox :value="t.ref"></b-form-checkbox>
                    </b-form-checkbox-group>
                  </td>
                  <td class="monoFont" width="140">{{ t.ref }}</td>
                  <td>{{ t.status }}</td>
                  <td>{{ t.title }}</td>
                  <td>{{ t.category }}</td>
                  <td>{{ t.subcategory }}</td>
                  <td>{{ t.createdBy }}</td>
                  <td>{{ t.createdByEmail }}</td>
                  <td>{{ t.source }}</td>
                  <td>{{ t.createdAt | formatDateTime }}</td>
                  <td>
                    <span v-if="t.status == 'Resolved'"> {{ t.updatedAt | formatDateTime }}</span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row" v-if="selected.length > 1">
              <div class="col-12">
                <button class="btn btn-outline-dark btn-xs mr-3" @click="batchResolve">Resolve</button>
                <button class="btn btn-outline-dark btn-xs mr-3" @click="showChangeCategoryModal">Change category</button>
                <!--  <button class="btn btn-outline-dark btn-xs mr-3">Re-Assign</button> -->
              </div>
            </div>
            <!--  <b-table :striped="true" :bordered="true" :borderless="false" :outlined="true" :small="true" :hover="true" :dark="false" :fixed="false" :foot-clone="false" :no-border-collapse="false" :items="tickets" :fields="fields" head-variant="light" table-variant="light"></b-table> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Change category and subcategory Modal  -->
    <b-modal id="ChangeCategoryModal" ref="ChangeCategoryModal" :title="`Change category for ${selected.length} tickets`" size="md" hide-footer no-close-on-backdrop>
      <form @submit.prevent="batchChangeCategory">
        <div class="form-group">
          <label for="category">Category of issues</label>
          <select @change="topSelected($event)" class="form-control" :class="{ 'is-invalid': $v.category.$error, 'is-valid': !$v.category.$invalid }">
            <option value="Select..." selected>Select...</option>
            <option v-for="(item, index) in categories" :key="index" :value="index">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.category.required">Category of issues is required.</span>
          </div>
        </div>
        <div class="form-group">
          <label for="subcategory">Subcategory</label>
          <select class="form-control" v-model="subcategory" :class="{ 'is-invalid': $v.subcategory.$error, 'is-valid': !$v.subcategory.$invalid }">
            <option v-for="(item, index) in subCategories" :key="index" :value="item.uuid">{{ item.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="!$v.subcategory.required">Subcategory of issues is required.</span>
          </div>
        </div>
        <button v-if="!spinner" class="btn btn-success" type="submit">Change</button>
        <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
      </form>
    </b-modal>
    <!-- Change category and subcategory Modal -->
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'
import SearchForm from '@/components/SearchForm.vue'
//import FilterDomain from '@/components/FilterDomain.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'BatchOps',

  data() {
    return {
      spinner: false,
      tickets: [],
      status: '',
      totalTickets: '',
      clients: [],
      label: '',
      // for checkbox
      refPool: [],
      selected: [],
      allSelected: false,
      indeterminate: false,
      // for category
      category: '',
      subcategory: '',
      categories: [],
      subCategories: []
    }
  },

  methods: {
    // search result to update tickets
    searchTickets(data) {
      this.tickets = data.tickets
      this.label = data.label
      this.totalTickets = data.tickets.length
      this.$store.state.pageTicketList = data.tickets
      // empty previous selection
      this.refPool = []
      for (var i = 0; i < data.tickets.length; i++) {
        this.refPool.push(data.tickets[i].ref)
      }
    },

    // Check all checkboxes
    toggleAll(checked) {
      this.selected = checked ? this.refPool.slice() : []
    },

    // Batch resolved
    batchResolve() {
      // console.log(this.selected)
      // console.log(JSON.stringify(this.selected))
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `You are about batch resolve ${this.selected.length} tickets`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, do it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios
              .get('/first-level/batch-resolve', {
                params: { ticketRefs: JSON.stringify(this.selected) }
              })
              .then(res => {
                if (res.data.status == 'success') {
                  this.$swal.fire('Resolved!', res.data.message, 'success')
                  this.tickets = []
                  this.refPool = []
                  this.selected = []
                  this.allSelected = false
                  this.indeterminate = false
                  this.totalTickets = ''
                } else {
                  this.$swal('', res.data.message, 'error')
                }
                loading.hide()
              })
            /* end of actural action */
          }
        })
    },

    batchChangeCategory() {
      let loading = this.$loading.show()

      axios
        .get('/first-level/batch-change-category', {
          params: {
            categoryUUID: this.category,
            subcategoryUUID: this.subcategory,
            ticketRefs: JSON.stringify(this.selected)
          }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$swal.fire('Changed!', res.data.message, 'success')
            this.tickets = []
            this.refPool = []
            this.selected = []
            this.allSelected = false
            this.indeterminate = false
            this.totalTickets = ''
            this.$refs['ChangeCategoryModal'].hide()
          } else {
            this.$swal('', res.data.message, 'error')
          }
          loading.hide()
        })
    },

    // To Category selection
    topSelected(e) {
      if (e.target.value == 'Select...') {
        return
      }
      this.subCategories = this.categories[e.target.value].subCategories
      this.category = this.categories[e.target.value].uuid
    },
    showChangeCategoryModal() {
      axios.get('/categories').then(res => {
        if (res.data.status == 'success') {
          this.categories = res.data.categories
          this.$refs['ChangeCategoryModal'].show()
        }
        this.spinner = false
      })
    }
  },

  watch: {
    selected(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.refPool.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },

  components: {
    // ChatAuthor,
    BackIcon,
    SearchForm
    //FilterDomain
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'ReferenceID',
          field: 'ref'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Subject',
          field: 'title'
        },
        {
          label: 'Category',
          field: 'category'
        },
        {
          label: 'Subcategory',
          field: 'subcategory'
        },

        {
          label: 'CreatedBy',
          field: 'createdBy'
        },
        {
          label: 'Email',
          field: 'createdByEmail'
        },
        /*  {
          label: "AssignedTo",
          field: "assignedTo",
        }, */
        {
          label: 'Channel',
          field: 'source'
        },
        {
          label: 'CreatedAt',
          field: 'createdAt'
        },
        {
          label: 'ResolvedAt',
          field: 'updatedAt'
        }
      ]
      return cols
    }
  },

  validations: {
    category: { required },
    subcategory: { required }
  }
}
</script>

<style scoped>
.monoFont {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9rem;
  font-weight: bold;
}
</style>
