<template>
  <div id="SLABreached" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">SLA Breached</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">SLA Breached ({{ reports.length }})</div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="reports"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                perPage: 50,
              }"
              :search-options="{
                enabled: true,
                trigger: 'enter',
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'Date', type: 'desc' }],
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <!-- <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">{{ props.row.name }}</span>
                <span v-if="props.column.field == 'subcategories'">{{ props.row.subcategories.length }}</span>
                <span v-if="props.column.field == 'description'">{{ props.row.description }}</span>
                <span v-if="props.column.field == 'edit'">
                  <router-link :to="{ name: 'EditCategoryAdmin', params: { uuid: props.row.uuid } }" class="btn btn-xs btn-outline-secondary" title="Update">edit</router-link>
                </span>
                <span v-if="props.column.field == 'delete'">
                  <button @click="deleteCategory(props.row.uuid, props.row.originalIndex)" class="btn btn-xs btn-outline-secondary" title="Delete">delete</button>
                </span>
              </template> -->
            </vue-good-table>
            <p class="mt-2">
              <Downloader :data="reports" fileName="UP_SLABreached" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Downloader from "@/components/Downloader.vue";

export default {
  name: "SLABreached",

  components: {
    Downloader,
  },

  data() {
    return {
      reports: [],
      name: "",
      description: "",
    };
  },

  mounted() {
    let loading = this.$loading.show();
    axios.get("/admin/sla-breached").then((res) => {
      if (res.data.status == "success") {
        this.reports = res.data.reports;
      }
      loading.hide();
    });
  },

  methods: {},

  computed: {
    columns() {
      let cols = [
        {
          label: "DATE",
          field: "Date",
          sortable: true,
        },
        {
          label: "Subject",
          field: "Subject",
          sortable: true,
        },
        {
          label: "BANKS",
          field: "Customer",
          sortable: true,
        },
        {
          label: "MERCHANT",
          field: "Customer",
          sortable: true,
        },

        {
          label: "CHANNEL",
          field: "Channel",
          sortable: true,
        },

        {
          label: "DEPENDENCY",
          field: "Department",
          sortable: true,
        },
        {
          label: "STATUS",
          field: "Status",
          sortable: true,
        },

        {
          label: "Comment",
          field: "Comment",
          sortable: false,
        },

        {
          label: "UNIQUE ID",
          field: "Ref",
          sortable: true,
        },

        {
          label: "AGE",
          field: "Age",
          sortable: false,
        },

         {
          label: 'Category',
          field: 'Category',
          sortable: true
        },
        {
          label: 'Subcategory',
          field: 'SubCategory',
          sortable: true
        }
      ];
      return cols;
    },
  },
};
</script>

<style></style>
