var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"SLACompliance"}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_vm._v("SLA Compliance ("+_vm._s(_vm.reports.length)+")")]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.reports,"line-numbers":true,"pagination-options":{
              enabled: true,
              perPage: 50
            },"search-options":{
              enabled: true,
              trigger: 'enter'
            },"sort-options":{
              enabled: true,
              initialSortBy: [{ field: 'Date', type: 'asc' }]
            },"styleClass":"vgt-table condensed myGoodTable"}}),_c('p',{staticClass:"mt-2"},[_c('Downloader',{attrs:{"data":_vm.reports,"fileName":"UP_SLACompliance"}})],1)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"pageTitle"},[_vm._v("SLA Compliance")])])])}]

export { render, staticRenderFns }