<template>
  <div id="AddDetailsModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Add Details" class="btn btn-outline-info btn-sm m-2">Add Details</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Add Details" size="lg" hide-footer no-close-on-backdrop>
      <div>
        <form @submit.prevent="handleSubmit">
          <!-- 1ST ROW -->
          <div class="row">
            <!-- Name -->
            <div class="col-6">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  v-model="name"
                  class="form-control"
                  placeholder="e.g. John Smith"
                  :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }"
                />
                <div class="invalid-feedback">
                  <span v-if="!$v.name.required">Name is required. </span>
                  <span v-if="!$v.name.minLength">Name must be at least {{ $v.name.$params.minLength.min }} letters. </span>
                  <span v-if="!$v.name.maxLength">Name must be at most {{ $v.name.$params.maxLength.max }} letters. </span>
                </div>
              </div>
            </div>

            <!-- Account Number  -->
            <div class="col-6">
              <div class="form-group">
                <label for="accountNumber">Account Number </label>
                <input
                  type="number"
                  v-model="accountNumber"
                  class="form-control"
                  placeholder="e.g. 1030009977"
                  :class="{ 'is-invalid': $v.accountNumber.$error, 'is-valid': !$v.accountNumber.$invalid }"
                />
                <div class="invalid-feedback">
                  <span v-if="!$v.accountNumber.required">Account Number is required. </span>
                  <span v-if="!$v.accountNumber.numeric">Account Number must be number. </span>
                  <span v-if="!$v.accountNumber.minLength"
                    >Account Number must be at least {{ $v.accountNumber.$params.minLength.min }} digits.
                  </span>
                  <span v-if="!$v.accountNumber.maxLength">Account Number must be at most {{ $v.accountNumber.$params.maxLength.max }} digits. </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 2ND ROW -->
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="actionTaken">Action Taken</label>
                <textarea
                  type="text"
                  v-model="actionTaken"
                  class="form-control"
                  placeholder="..."
                  :class="{ 'is-invalid': $v.actionTaken.$error, 'is-valid': !$v.actionTaken.$invalid }"
                  rows="3"
                ></textarea>
                <div class="invalid-feedback">
                  <span v-if="!$v.actionTaken.required">Action taken is required. </span>
                  <span v-if="!$v.actionTaken.minLength">Action taken must be at least {{ $v.actionTaken.$params.minLength.min }} letters. </span>
                  <span v-if="!$v.actionTaken.maxLength">Action taken must be at most {{ $v.actionTaken.$params.maxLength.max }} letters. </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 3RD ROW -->
          <div class="row">
            <!-- Root Cause -->
            <div class="col-6">
              <div class="form-group">
                <label for="rootCause">Root Cause</label>
                <select
                  type="text"
                  v-model="rootCause"
                  class="form-control"
                  placeholder="e.g. John Smith"
                  :class="{ 'is-invalid': $v.rootCause.$error, 'is-valid': !$v.rootCause.$invalid }"
                >
                  <option value="" selected>Select...</option>
                  <option value="People">People</option>
                  <option value="Process">Process</option>
                  <option value="Technology">Technology</option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="!$v.rootCause.required">Root Cause is required. </span>
                </div>
              </div>
            </div>

            <!-- Amount  -->
            <div class="col-6">
              <div class="form-group">
                <label for="amount">Amount </label>
                <input
                  type="number"
                  v-model="amount"
                  class="form-control"
                  placeholder="e.g. 2000"
                  :class="{ 'is-invalid': $v.amount.$error, 'is-valid': !$v.amount.$invalid }"
                />
                <div class="invalid-feedback">
                  <span v-if="!$v.amount.required">Account Number is required. </span>
                  <span v-if="!$v.amount.numeric">Account Number must be number. </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 4TH ROW -->
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="delayReason">Reason for delay in Resolution</label>
                <textarea
                  type="text"
                  v-model="delayReason"
                  class="form-control"
                  placeholder="..."
                  :class="{ 'is-invalid': $v.delayReason.$error, 'is-valid': !$v.delayReason.$invalid }"
                  rows="3"
                ></textarea>
                <div class="invalid-feedback">
                  <span v-if="!$v.delayReason.required">Delay reason is required. </span>
                  <span v-if="!$v.delayReason.minLength">Delay reason must be at least {{ $v.delayReason.$params.minLength.min }} letters. </span>
                  <span v-if="!$v.delayReason.maxLength">Delay reason must be at most {{ $v.delayReason.$params.maxLength.max }} letters. </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 4TH ROW -->
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="email">Expected Closure Date</label>
                <input
                  v-model="dateExpected"
                  type="date"
                  class="form-control"
                  min="2021-01-01"
                  :max="maxDate"
                  :class="{ 'is-invalid': $v.dateExpected.$error, 'is-valid': !$v.dateExpected.$invalid }"
                />
                <div class="invalid-feedback">
                  <span v-if="!$v.dateExpected.required">Date is required.</span>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <button v-if="!spinner" type="submit" class="btn btn-warning">Submit</button>
            <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { name } from '@chenfengyuan/vue-countdown'
import axios from 'axios'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'AddDetailsModal',

  data() {
    return {
      spinner: false,
      modalID: 'AddDetailsModal',

      name: this.ticket?.user?.name,
      accountNumber: '1122334455',
      actionTaken: 'This is what I did',
      rootCause: '',
      delayReason: 'This is why it was delayed',
      amount: '200',
      dateExpected: ''
      //timePicked: '10:00'
    }
  },

  props: {
    ticket: { type: Object }
  },

  mounted() {
    //this.name = this.ticket.user.name
    /* axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categories
      }
      this.spinner = false
    }) */
  },

  methods: {
    handleSubmit() {
      this.spinner = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('accountNumber', this.accountNumber)
      formData.append('actionTaken', this.actionTaken)
      formData.append('rootCause', this.rootCause)
      formData.append('delayReason', this.delayReason)
      formData.append('amount', this.amount)
      formData.append('dateExpected', this.dateExpected)
      formData.append('ref', this.ticket.ref)

      axios.post('/add-bank-details', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('Details added', res.data.message, 'success')
          //this.$emit('categoryChangeSuccess', res.data.cat)
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    },

    authUser() {
      return this.$store.state.authUser
    },

    maxDate() {
      // get a date in this format: 2021-01-01
      return new Date().toISOString().split('T')[0]
    }
  },

  validations: {
    name: { required, minLength: minLength(2), maxLength: maxLength(30) },
    accountNumber: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
    actionTaken: { required, minLength: minLength(4), maxLength: maxLength(1000) },
    rootCause: { required },
    amount: { required },
    delayReason: { required, minLength: minLength(4), maxLength: maxLength(1000) },
    dateExpected: { required }
  }
}
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4eadea;
  border-color: #4eadea;
}
</style>
