import auth from "../helper/auth";
import PasswordResetNew from "../views/PasswordResetNew.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PageNotFound from "../views/PageNotFound.vue";

import TicketDetails from "../views/TicketDetails.vue";
import TicketCreate from "../views/TicketCreate.vue";
import Verifiable from "../views/Verifiable.vue";
import MyTickets from "../views/MyTickets.vue";

import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import Faq from "../views/Faq.vue";

const publicRoutes = [
  {
    path: "/",
    component: () => import("../layouts/Public.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: TicketCreate,
      },
      {
        path: "/create",
        name: "TicketCreate",
        component: TicketCreate,
      },
      {
        path: "/ticket/:ref",
        name: "TicketDetails",
        component: TicketDetails,
      },

      {
        path: "/my-tickets",
        name: "Tickets",
        component: MyTickets,
      },
      {
        path: "/faq",
        name: "Faq",
        component: Faq,
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        //beforeEnter: auth.ifNotAuthenticated,
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
      },

      {
        path: "/password-reset",
        name: "PasswordReset", //
        component: PasswordReset,
        // beforeEnter: auth.ifNotAuthenticated,
      },

      {
        path: "/password-reset/token/:token",
        name: "PasswordResetNew", //
        component: PasswordResetNew,
        //beforeEnter: auth.ifNotAuthenticated,
      },

      {
        path: "/verify/:verifiable/:token",
        //path: '/verifiable',
        name: "Verifiable",
        component: Verifiable,
      },

      {
        path: "/*",
        name: "PageNotFound",
        component: PageNotFound,
      },
    ],
  },
];

export default publicRoutes;
