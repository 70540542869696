<template>
  <div id="SLACompliance" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">TOP 10 CASES</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <highcharts :options="chartOptions"></highcharts>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">TOP 10 CASES ({{ reports.length }})</div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="reports"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                trigger: 'enter',
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'Count', type: 'asc' }],
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <!-- <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">{{ props.row.name }}</span>
                <span v-if="props.column.field == 'subcategories'">{{ props.row.subcategories.length }}</span>
                <span v-if="props.column.field == 'description'">{{ props.row.description }}</span>
                <span v-if="props.column.field == 'edit'">
                  <router-link :to="{ name: 'EditCategoryAdmin', params: { uuid: props.row.uuid } }" class="btn btn-xs btn-outline-secondary" title="Update">edit</router-link>
                </span>
                <span v-if="props.column.field == 'delete'">
                  <button @click="deleteCategory(props.row.uuid, props.row.originalIndex)" class="btn btn-xs btn-outline-secondary" title="Delete">delete</button>
                </span>
              </template> -->
            </vue-good-table>
            <p class="mt-2">
              <Downloader :data="reports" fileName="UP_Top10Cases" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Chart } from "highcharts-vue";
import Downloader from "@/components/Downloader.vue";

export default {
  name: "Top10Cases",

  components: {
    Downloader,
    highcharts: Chart,
  },

  data() {
    return {
      reports: [],
    };
  },

  mounted() {
    let loading = this.$loading.show();
    axios.get("/admin/top-10-cases").then((res) => {
      if (res.data.status == "success") {
        this.reports = res.data.reports;
      }
      loading.hide();
    });
  },

  methods: {},

  computed: {
    columns() {
      let cols = [
        {
          label: "ISSUE TYPE",
          field: "Subcategory",
          sortable: true,
        },

        {
          label: "CATEGORY",
          field: "Category",
          sortable: true,
        },

        {
          label: "COUNT",
          field: "Count",
          sortable: true,
        },

        {
          label: "DEPENDENCY",
          field: "Department",
          sortable: true,
        },

        /* {
          label: "CategoryType",
          field: "CategoryType",
          sortable: true,
        }, */
      ];
      return cols;
    },

    chartOptions() {
      let names = [];
      let count = [];

      for (let index = 0; index < this.reports.length; index++) {
        names.push(this.reports[index].Subcategory);
        count.push(this.reports[index].Count);
      }

      return {
        chart: {
          //type: "spline",
          //type: "column",
          //type: "area",
          type: "line",
        },
        series: [
          /* {
            name: "Raised",
            data: raised,
            color: "#0061f2"
          }, */
          {
            name: "ISSUE TYPE",
            data: count,
            color: "#e81500",
          },
        ],
        xAxis: {
          categories: names,
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "Count",
          },
        },

        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        credits: {
          enabled: false,
        },
      };
    },
  },
};
</script>

<style></style>
