<template>
  <div>
    <vue-blob-json-csv @success="handleSuccess" @error="handleError" tag-name="div" file-type="csv" :file-name="fileName" title="Download" :data="data" class="btn btn-sm btn-secondary" />
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    fileName: String,
  },
  methods: {
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e);
    },
  },
};
</script>

<style></style>
